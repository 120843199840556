<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Dispêndio de LI para RDA
    </v-card-title>
    <v-card-text>
      <div class="d-flex align-center pt-0">
        <div class="table-v-action-button mt-2 mr-2" @click="openEditModal">
          <v-icon>mdi-square-edit-outline</v-icon>
          Editar tipo
        </div>
        <v-spacer></v-spacer>
        <v-switch
          style="margin-bottom: -11px"
          class="mr-3"
          v-model="isComplete"
          :label="completeSwitchLabel"
        ></v-switch>
        <div
          class="table-v-action-button mr-3 pt-1"
          @click="exportXlsx(arrayToPrint)"
        >
          <v-icon>mdi-file-excel-outline</v-icon>Exportar XLSX
        </div>
        <monthly-filter
          style="width: 270px"
          @change="doLoad()"
        ></monthly-filter>
      </div>
    </v-card-text>
    <v-card-text style="padding: 0" class="rda-report">
      <div
        class="font-weight-bold text-uppercase text-center table-outer-title"
      >
        Dispêndio
      </div>
      <div class="table-container">
        <table v-if="isComplete">
          <tr>
            <th style="width: 25%">Recursos Humanos</th>
            <td style="width: 25%">{{ dispendio.rh | toCurrency }}</td>
            <th style="width: 25%">Serviços Técnicos de Terceiros</th>
            <td style="width: 25%">
              {{ dispendio.servico | toCurrency }}
            </td>
          </tr>
          <tr>
            <th>Equipamentos e Software</th>
            <td>{{ dispendio.equipamentos | toCurrency }}</td>
            <th>Treinamentos</th>
            <td>{{ dispendio.treinamento | toCurrency }}</td>
          </tr>
          <tr>
            <th>Obras Civis</th>
            <td>{{ dispendio.obras | toCurrency }}</td>
            <th>Viagens</th>
            <td>{{ dispendio.viagens | toCurrency }}</td>
          </tr>
          <tr>
            <th>Livros ou Periódicos</th>
            <td>{{ dispendio.livros | toCurrency }}</td>
            <th>Outros Correlatos</th>
            <td>{{ dispendio.outros | toCurrency }}</td>
          </tr>
          <tr>
            <th>Material de Consumo</th>
            <td colspan="3">
              {{ dispendio.material | toCurrency }}
            </td>
          </tr>
          <tr>
            <th colspan="2">
              Total válido para cumprimento de obrigação no ano base
            </th>
            <td colspan="2">{{ dispendio.total | toCurrency }}</td>
          </tr>
        </table>
        <table v-else>
          <tr>
            <th style="width: 25%">Recursos Humanos</th>
            <td style="width: 25%">
              {{ dispendioSimplificado.rh | toCurrency }}
            </td>
            <th style="width: 25%">Equipamentos e Software</th>
            <td style="width: 25%">
              {{ dispendioSimplificado.equipamentos | toCurrency }}
            </td>
          </tr>
          <tr>
            <th>Obras Civis</th>
            <td>{{ dispendioSimplificado.obras | toCurrency }}</td>
            <th>Materiais para Protótipo</th>
            <td>
              {{ dispendioSimplificado.prototipo | toCurrency }}
            </td>
          </tr>
          <tr>
            <th>Material de Consumo</th>
            <td colspan="3">
              {{ dispendioSimplificado.material | toCurrency }}
            </td>
          </tr>
          <tr>
            <th colspan="2">
              Total válido para cumprimento de obrigação no ano base
            </th>
            <td colspan="2">{{ dispendioSimplificado.total | toCurrency }}</td>
          </tr>
        </table>
      </div>

      <div
        v-if="dispendioRH"
        class="table-outer-title d-flex align-center justify-space-between"
      >
        <div class="font-weight-bold text-uppercase pl-5">
          Dispêndio — Recursos Humanos
        </div>
        <div style="width: 50%">
          <v-pagination
            v-model="rhPage"
            :length="dispendioRH.length"
            :total-visible="7"
          ></v-pagination>
        </div>
      </div>
      <v-carousel
        v-model="rhPanel"
        class="mb-3"
        hide-delimiters
        :show-arrows="false"
        :height="'fit-content'"
      >
        <v-carousel-item
          v-for="(rh, index) in dispendioRH"
          :key="index"
          class="table-container"
        >
          <table>
            <tr>
              <th rowspan="2" style="width: 25%">
                Dispêndio RH Direto Colaborador
              </th>
              <th style="width: 25">CPF</th>
              <td colspan="2" style="width: 50%">{{ rh.CPF }}</td>
            </tr>
            <tr>
              <th style="width: 25%">Nome</th>
              <td colspan="2" style="width: 50%">{{ rh.nome }}</td>
            </tr>
            <tr>
              <th>Nível de Escolaridade</th>
              <td>{{ rh.escolaridade }}</td>
              <th>Formação</th>
              <td style="width: 25%">{{ rh.formacao }}</td>
            </tr>
            <tr>
              <th>Total de Horas</th>
              <td>{{ rh.horas_tot }}</td>
              <th>Total Gasto</th>
              <td>{{ rh.valor_tot | toCurrency }}</td>
            </tr>
          </table>
          <table>
            <tr>
              <th style="width: 25%">Projeto</th>
              <th style="width: 10%">Tipo RH</th>
              <th style="width: 15%">Horas Trabalhadas</th>
              <th style="width: 20%">Período de atuação</th>
              <th style="width: 10%">Valor</th>
              <th style="width: 20%">Atuação no projeto</th>
            </tr>
            <tr v-for="(projeto, index) in rh.projetos" :key="index">
              <td class="vertical-align-top">{{ projeto.titulo }}</td>
              <td class="vertical-align-top">{{ projeto.tipo_rh }}</td>
              <td class="vertical-align-top">{{ projeto.horas }}</td>
              <td class="vertical-align-top">
                {{ atuacao(projeto.atuacao_projeto) }}
              </td>
              <td class="vertical-align-top">
                {{ projeto.valor | toCurrency }}
              </td>
              <td
                class="vertical-align-top clickable"
                @click="openProjectActivities(projeto)"
              >
                {{ formatarAtividades(projeto.atividades) }}
              </td>
            </tr>
          </table>
        </v-carousel-item>
      </v-carousel>
      <kore-modal
        :max-width="'80%'"
        :value="activitiesModal"
        :title="`Atividades em ${
          selectedProject ? selectedProject.titulo : ''
        }`"
        hide-actions
        @cancel="closeActivitiesModal"
      >
        <v-data-table
          :items="selectedProject ? selectedProject.atividades : []"
          :headers="[
            {
              text: 'Atividade',
              value: 'atividade',
              align: 'center',
            },
            {
              text: 'Comentário',
              value: 'comentario',
              align: 'center',
            },
          ]"
          hide-default-footer
          fixed-header
          disable-sort
          disable-pagination
        >
          <template v-slot:item="{ item, headers }">
            <tr class="table-v-tr" style="cursor: default">
              <td v-for="col in headers" :key="col.value">
                {{ item[col.value] }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </kore-modal>
      <form-modal
        :cols="cols"
        :opened.sync="editModalOpening"
        :value.sync="editModalData"
        @save="doSave"
      ></form-modal>
    </v-card-text>
  </v-card>
</template>

<script>
import { isNumber } from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { ReportTypeEnum } from "@/core/enums/dispendio-li-rda";

export default {
  components: {
    "form-modal": () => import("@/components/form-modal.vue"),
    "kore-modal": () => import("@/components/kore-modal.vue"),
    "monthly-filter": () => import("@/components/monthly-filter.vue"),
  },
  computed: {
    ...mapGetters(["clientId", "intervaloCompetencia"]),
    arrayToPrint: function () {
      const colunas = [
        "Título do projeto",
        "Dispêndio",
        "CPF",
        "Número passaporte",
        "País emissor do passaporte",
        "Nome do estrangeiro",
        "E-mail do estrangeiro",
        "Data vencimento do passaporte",
        "Data nascimento do estrangeiro",
        "Sexo do estrangeiro",
        "Nível Escolaridade",
        "Formação",
        "Especificar formação",
        "Horas trabalhadas",
        "Data início atuação",
        "Data fim atuação",
        "Valor",
        "Atuação no projeto",
      ];
      var array = [colunas];
      this.dispendioRH.forEach((colaborador) => {
        const {
          CPF,
          formacao,
          formacao_id,
          horas,
          atuacao,
          valor,
          escolaridade_id,
          projetos,
          nome,

          // Propriedades que irão vir em alguma atualização futura do backend
          estrangeiro,
          num_passaporte,
          pais_emissor,
          email,
          vncmt_passaporte,
          nascimento,
          sexo,
        } = colaborador;

        const CPFSemFormatacao = CPF?.replace(/\D+/g, "") || "";

        // Formatando dados para quando estrangeiro
        const numPassaporte =
          estrangeiro && isNumber(num_passaporte)
            ? num_passaporte.toString()
            : "";
        const paisEmissor = estrangeiro ? pais_emissor : "";
        const nomeEstrangeiro = estrangeiro ? nome : "";
        const emailEstrangeiro = estrangeiro ? email : "";
        const vncmtPass = moment(vncmt_passaporte);
        const vencimentoPass =
          estrangeiro && vncmtPass.isValid()
            ? vncmtPass.format("DD/MM/YYYY")
            : "";
        const dataNascimento = moment(nascimento);
        const nascimentoEstrangeiro =
          estrangeiro && dataNascimento.isValid()
            ? dataNascimento.format("DD/MM/YYYY")
            : "";
        const identidadeDeGenero = isNumber(sexo) ? sexo.toString() : "";

        // Dados tanto de nacionais quanto estrangeiros
        const [inicioISO, fimISO] = atuacao.split(" - ");
        const inicioAtuacao = moment(inicioISO).format("DD/MM/YYYY");
        const fimAtuacao = moment(fimISO).format("DD/MM/YYYY");

        const codigoEscolaridade = isNumber(escolaridade_id)
          ? escolaridade_id.toString()
          : "";
        const codigoFormacao = isNumber(formacao_id)
          ? formacao_id.toString()
          : "";
        const nomeFormacao = formacao || "";
        const horasFormatadas = isNumber(horas) ? horas.toString() : "";

        const valorFormatado = isNumber(valor) ? valor.toFixed(2) : "";

        const rows = projetos.map((projeto) => {
          const { titulo, tipo_rh_id, atividades } = projeto;
          const dispendio = isNumber(tipo_rh_id) ? tipo_rh_id.toString() : "";
          const todasAtividades = atividades
            .map(({ atividade }) => atividade)
            .join(", ")
            .replace(";", ".,");
          const atuacaoNoProjeto =
            todasAtividades.length > 1000
              ? todasAtividades.substring(0, 997).concat("...")
              : todasAtividades;
          const row = [
            titulo,
            dispendio,
            CPFSemFormatacao,
            numPassaporte,
            paisEmissor,
            nomeEstrangeiro,
            emailEstrangeiro,
            vencimentoPass,
            nascimentoEstrangeiro,
            identidadeDeGenero,
            codigoEscolaridade,
            codigoFormacao,
            nomeFormacao,
            horasFormatadas,
            inicioAtuacao,
            fimAtuacao,
            valorFormatado,
            atuacaoNoProjeto,
          ];
          return row;
        });
        array.push(...rows);
      });
      return array;
    },
    cols: function () {
      let toEdit = [
        {
          id: true,
          nome: "Completo",
        },
        {
          id: false,
          nome: "Simplificado",
        },
      ];

      return [
        {
          key: "relatorioRDA",
          name: "Tipo de relatório",
          type: this.$fieldTypes.SWITCH,
          rel: { toEdit, key: "id", name: "nome" },
          rules: [{ rule: "required" }],
        },
      ];
    },
    resource: function () {
      return this.apiResource(
        `/v1/rh/clientes/${this.clientId}/dispendio/resumopedli`
      );
    },
    rhPage: {
      get: function () {
        return this.rhPanel + 1;
      },
      set: function (value) {
        this.rhPanel = value - 1;
      },
    },
    completeSwitchLabel() {
      return this.isComplete ? "Completo" : "Simplificado";
    },
  },
  data: function () {
    return {
      dispendio: {},
      dispendioRH: [],
      dispendioSimplificado: {},
      editModalData: {},
      editModalOpening: false,
      rhPanel: 0,
      isComplete: true,
      activitiesModal: false,
      selectedProject: null,
    };
  },
  methods: {
    atuacao: function (value) {
      if (!value || value === "") {
        return "";
      }

      const dates = value.split(" - ");
      const { toDate } = this.$options.filters || {};
      const formattedDates = toDate ? dates.map(toDate) : dates;
      return formattedDates.join(" - ");
    },
    doSave: function (data, close) {
      const tipoRelatorio = data.relatorioRDA
        ? ReportTypeEnum.COMPLETO
        : ReportTypeEnum.SIMPLIFICADO;
      const [competenciaIni, competenciaFim] = this.intervaloCompetencia;
      const query = `competenciaIni=${competenciaIni}&competenciaFim=${competenciaFim}&tipoRelatorio=${tipoRelatorio}`;
      const url = `v1/rh/clientes/${this.clientId}/dispendio/tiporesumopedli?${query}`;
      this.apiResource(url)
        .save({})
        .then(this.doLoad)
        .then(() => {
          if (close) {
            close();
          }
        });
    },
    formatarAtividades: function (value) {
      if (!Array.isArray(value)) {
        return value;
      }

      const [first, ...others] = value;
      if (others.length > 1) {
        return first.atividade.concat(` e mais ${others.length} atividades`);
      } else if (others.length === 1) {
        return first.atividade.concat(` e mais uma atividade`);
      } else {
        return first.atividade;
      }
    },
    openProjectActivities: function (project) {
      this.selectedProject = project;
      this.activitiesModal = true;
    },
    openEditModal: function () {
      this.editModalData = { relatorioRDA: this.isComplete };
      this.editModalOpening = true;
    },
    closeActivitiesModal: function () {
      this.activitiesModal = false;
    },
    doLoad: function () {
      const [competenciaIni, competenciaFim] = this.intervaloCompetencia;
      const query = `competenciaIni=${competenciaIni}&competenciaFim=${competenciaFim}`;
      const url = `/v1/rh/clientes/${this.clientId}/dispendio/resumopedli?${query}`
      this.apiResource(url)
        .get()
        .then(
          ({
            dispendio,
            dispendio_rh,
            dispendio_simplificado,
            relatorioRDA,
          }) => {
            this.dispendio = dispendio || {};
            this.dispendioSimplificado = dispendio_simplificado || {};
            this.dispendioRH = dispendio_rh || [];
            this.isComplete = relatorioRDA === ReportTypeEnum.COMPLETO;
          }
        );
    },
  },
  watch: {
    clientId: function () {
      this.doLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-card > .v-card__text {
  color: #212529;
}

.rda-report {
  background-color: rgb(244, 244, 244);
}

.table-outer-title {
  background-color: #cee7e7;
  padding: 12px;
}

.table-container {
  table {
    width: 100%;
  }

  table + table {
    margin-top: -1px;
  }

  th,
  td {
    padding: 12px;
    color: #212529;

    &.vertical-align-top {
      vertical-align: top;
    }
  }

  th {
    opacity: 0.8;
  }

  table,
  tr,
  th,
  td {
    border: solid thin lightgray;
  }

  .clickable {
    cursor: pointer;
  }
}
</style>
